<template>
    <div>
        <div class="consult-information-add">
            <el-button v-if="infoData.creator_id === userID" size="mini" type="primary" @click="dialogVisible = true; form = {
                customers_id: infoData.id
            }">添加
            </el-button>
        </div>
        <el-table v-loading="tableLoading" :data="tableList" style="width: 100%">
            <el-table-column label="Id" prop="id" width="80">
            </el-table-column>
            <el-table-column label="咨询类型">
                <template slot-scope="scope">
                    {{ getType(scope.row.type) }}
                </template>
            </el-table-column>
            <el-table-column label="图片">
                <template slot-scope="scope">
                    <div v-if="scope.row.pics">
                        <el-image v-if="scope.row.pics[0]" :preview-src-list="scope.row.pics" :src="scope.row.pics[0]"
                                  style="width: 100px; height: 100px">
                        </el-image>
                    </div>

                </template>
            </el-table-column>
            <el-table-column label="咨询时间" prop="created_at">
            </el-table-column>
            <el-table-column label="备注" prop="remark"></el-table-column>
            <el-table-column label="创建人" prop="creator_name">
            </el-table-column>

            <el-table-column v-if="infoData.creator_id === userID" label="操作">
                <template slot-scope="scope">

                    <el-link size="small" @click="modification(scope.row)" type="primary">修改</el-link>

                    -
                    <span>
                        <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                       @confirm="customerConsultDeleted(scope.row)">
                            <el-link slot="reference" type="primary">删除</el-link>
                        </el-popconfirm>
                    </span>
                </template>
            </el-table-column>
        </el-table>
        <div class="table-batch">
            <span class="fl-r">
                <el-pagination :current-page="page" :page-size="pageSize" :page-sizes="[10, 20]" :total="total"
                               background
                               layout="total, sizes, prev, pager, next, jumper" small @size-change="setPageSize"
                               @current-change="setPage">
                </el-pagination>
            </span>
            <div style="clear: both"></div>
        </div>


        <el-dialog append-to-body title="添加" :visible.sync="dialogVisible" width="60%">
            <div>
                <el-form ref="form" :model="form" :rules="rules" label-width="140px" size="mini">
                    <el-form-item class="input" label="咨询类型: " label-position="left" prop="type">
                        <el-select v-model="form.type" class="search-select" clearable placeholder="--请选择类超--"
                                   size="mini">
                            <el-option label="书本" value="1"></el-option>
                            <el-option label="课程" value="2"></el-option>
                            <el-option label="资料" value="3"></el-option>
                            <el-option label="其他" value="4"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="备注: " label-position="left" prop="remark">
                        <el-input v-model="form.remark" style="width: 500px" type="textarea"></el-input>
                    </el-form-item>

                    <el-form-item label="图片: " label-position="left" prop="pics">
                        <paste-image upload-path="sales" @onDelete="onDelete" @onSuccess="onSuccess"></paste-image>
                    </el-form-item>
                    <el-form-item>
                        <el-button :loading="loading" size="small" type="primary" @click="onSubmit">
                            保存
                        </el-button>
                        <el-button size="small" style="margin-left: 10px" @click="dialogVisible = false">
                            取消
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog append-to-body title="编辑" :visible.sync="dialogVisible2" width="60%">
            <div>
                <el-form v-loading="formLoading" ref="form" :model="form" :rules="rules" label-width="140px"
                         size="mini">
                    <el-form-item class="input" label="咨询类型: " label-position="left" prop="type">
                        <el-select v-model="form.type" class="search-select" clearable placeholder="--请选择类超--"
                                   size="mini">
                            <el-option label="书本" :value="1"></el-option>
                            <el-option label="课程" :value="2"></el-option>
                            <el-option label="资料" :value="3"></el-option>
                            <el-option label="其他" :value="4"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="备注: " label-position="left" prop="remark">
                        <el-input v-model="form.remark" style="width: 500px" type="textarea"></el-input>
                    </el-form-item>

                    <el-form-item label="图片: " label-position="left" prop="pics">
                        <paste-image :picArray="form.pics" upload-path="sales" @onDelete="onDelete"
                                     @onSuccess="onSuccess"></paste-image>
                    </el-form-item>
                    <el-form-item>
                        <el-button :loading="loading" size="small" type="primary" @click="onSubmit">
                            保存
                        </el-button>
                        <el-button size="small" style="margin-left: 10px" @click="dialogVisible2 = false">
                            取消
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {config} from "@/js/config";
import PasteImage from "@/components/pasteImage/index";

export default {
    name: "list",
    components: {
        PasteImage
    },
    data() {
        return {
            formLoading: false,
            tableList: [],
            tableLoading: false,
            page: 1,
            pageSize: 10,
            total: 0,
            path: config.imageUrl,
            dialogVisible: false,
            loading: false,
            form: {
                pics: [],
            },
            dialogVisible2: false,
            rules: {

                type: [
                    {required: true, trigger: "change", message: "请选择类型"},
                ],
                remark: [
                    {required: true, trigger: "blur", message: "请输入备注"},
                ],

            },
        };
    },
    computed: {
        ...mapState({
            userID: (state) => state.user.info.id,
        }),
    },
    props: {
        infoData: {
            type: Object,
        },
        load: {
            type: Boolean
        }
    },
    watch: {
        infoData: {
            handler(newValue) {
                if (this.load && newValue.id) {
                    this.getList();
                } else {
                    this.tableList = [];
                }
            },
            immediate: true,
            deep: true
        },
        load(newValue) {
            if (this.load && this.infoData.id) {
                this.getList();
            }
        },
    },
    methods: {
        ...mapActions("customerCard", [
            "listCustomerConsult",
            "getCustomerConsult",
            "addCustomerConsult",
            "delCustomerConsult"
        ]),
        async modification({id}) {
            this.dialogVisible2 = true
            this.formLoading = true;
            const {data} = await this.getCustomerConsult({id})
            this.form = data
            this.formLoading = false
        },
        onSuccess(pic) {
            this.form.pics = pic
        },
        onDelete(pic) {
            this.form.pics = pic
        },
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.addClass();
                }
            });
        },
        async addClass() {
            this.loading = true;
            const {res_info} = await this.addCustomerConsult(this.form);
            this.loading = false;
            this.dialogVisible = false
            this.dialogVisible2 = false
            if (res_info != "ok") return;
            this.getList()
            this.$message.success("操作成功");
        },
        async customerConsultDeleted(row) {
            const {res_info} = await this.delCustomerConsult({id: row.id});
            if (res_info !== "ok") return;
            this.getList()
            this.$message.success("删除成功");
        },
        setPageSize(pageSize) {
            this.pageSize = pageSize;
            this.getList();
        },
        setPage(page) {
            this.page = page;
            this.getList();
        },
        getType(type) {
            // 咨询类型：1.书本，2.课程，3.资料，4.其他
            switch (type) {
                case 1:
                    return "书本";
                case 2:
                    return "课程";
                case 3:
                    return "资料";
                case 4:
                    return "其他";
            }
        },
        async getList() {
            let customers_id = this.infoData.id
            this.tableLoading = true;
            let form = {
                customers_id,
                page: this.tablePage,
                pageSize: this.tablePageSize,
            };
            const {data} = await this.listCustomerConsult(form);

            this.tableList = data.list.map((v) => {
                if (v.pics != null) {
                    v.pics = v.pics.map((c) => this.path + c);
                }
                return v;
            });
            this.tableTotal = data.total;
            this.tableLoading = false;

        },
    },
};
</script>
