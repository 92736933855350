<template>
  <div class="main">
    <div v-loading="listLoading" class="list">
      <div class="search">
        <div style="display: flex; justify-content: space-between;border: 1px solid #f1f1f1;border-radius: 8px;margin-bottom: 10px">
          <div class="get-info">
            <div>今日已经领取</div>
            <div style="margin-top:10px">{{claimed}}</div>
          </div>
          <div class="get-info">
            <div>未领取总数</div>
            <div style="margin-top:10px">{{unclaimed}}</div>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <el-select style="width: 140px" v-model="searchData.searchType" class="search-select" clearable placeholder="搜索的群体" size="mini">
            <el-option label="用户" value="1"> </el-option>
            <el-option label="客户" value="2"> </el-option>
          </el-select>
          <el-input v-model="searchData.q" class="search-input" clearable placeholder="请输入ID或者昵称" size="mini"></el-input>

        </div>
<!--        <el-select v-model="searchData.status" class="search-select" clearable placeholder="状态" size="mini">-->
<!--          <el-option label="未服务" value="0"> </el-option>-->
<!--          <el-option label="服务中" value="1"> </el-option>-->
<!--          <el-option label="已退费" value="2"> </el-option>-->
<!--        </el-select>-->

        <div>
          <el-button size="mini" type="primary" @click="search">搜索</el-button>

          <el-button v-if="role === 1 || role === 7 || role === 8" size="mini" type="primary"
            @click="$router.push('/customerCard/add')">新增</el-button>
          <el-button v-if="role === 1 || role === 5" size="mini" type="primary" @click="gain">获取</el-button>
        </div>
      </div>
      <div v-for="item in list" :key="item.id" :class="item.id === active ? 'active' : ''" class="list-item"
        @click="getUserInfo(item)">
        <div class="top-box">
          <div class="item-info">
            <img v-if="item.avatar_url" :src="item.avatar_url" alt="" class="image" />
            <div v-else class="image"></div>
            <div class="id_name">
              <div>ID:{{ item.id }}</div>
              <div>{{ item.nickname }}</div>
            </div>
          </div>
        </div>
        <div class="bottom-box">创建时间: {{ item.created_at }}</div>
      </div>

      <div class="block">
        <el-pagination small :current-page="page" :page-size="pageSize" :total="total" background layout="prev, pager, next"
          @current-change="setPage">
        </el-pagination>
      </div>
    </div>
    <div v-loading="infoLoading || listLoading" class="info-information">
      <div class="info-top-box">
        <div class="user-info">
          <img v-if="infoData.avatar_url" :src="infoData.avatar_url" alt="" class="image" />
          <div v-else class="image"></div>
          <div class="id_name">
            <div>
              ID:{{ infoData.user_id }}
              <el-button v-if="infoData.user_id === 0" class="binding" size="mini" type="primary"
                @click="binding">绑定</el-button>

              <el-button v-else class="binding" size="mini" type="primary" @click="unfasten">解绑</el-button>
            </div>
            <div>{{ infoData.user_name }}</div>
          </div>
        </div>
        <div class="compile">
          <el-button size="mini" type="primary" @click="editInfo">编辑</el-button>
        </div>
      </div>
<!--      <div class="material">-->
<!--        <div class="material-left">-->
<!--          <div>-->
<!--            <span>咨询来源：</span><span>{{ source(infoData.source) }}</span>-->
<!--          </div>-->
<!--          <div>-->
<!--            <span>手机号/微信号：</span><span>{{ infoData.contact_number }}</span>-->
<!--          </div>-->
<!--          <div>-->
<!--            <span>创建时间：</span><span>{{ infoData.created_at }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="material-right">-->
<!--          <div style="display: flex">-->
<!--            <span>意向度：</span><span>-->
<!--              <el-rate v-model="infoData.intention" disabled text-color="#ff9900">-->
<!--              </el-rate>-->
<!--            </span>-->
<!--          </div>-->
<!--          <div style="display: flex; align-items: center">-->
<!--            <span>状态：</span>-->
<!--            <div v-if="infoData.status === 1" class="state-up-v">服务中</div>-->
<!--            <div v-else-if="infoData.status === 0" class="state-down-v">未服务</div>-->
<!--            <div v-else-if="infoData.status === 2" class="state-red-v">已退费</div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <span>归属人：</span><span>{{ infoData.attributor_name }}</span>-->
<!--            <span style="margin: 0 10px">-->
<!--              <el-button v-if="role === 1" size="mini" type="primary" @click="modification">修改</el-button>-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <el-descriptions>
        <el-descriptions-item label="咨询来源">{{
          source(infoData.source)
          }}</el-descriptions-item>
        <el-descriptions-item label="号码类型">
            <span v-if="infoData.contact_type === 1">{{
              微信号
            }}</span>
          <span v-else-if="infoData.contact_type === 2">手机号</span>
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          infoData.created_at
          }}</el-descriptions-item>
        <el-descriptions-item label="联系号码">{{
          infoData.contact_number
          }}</el-descriptions-item>
        <el-descriptions-item label="意向度">
          <div>
            <el-rate v-model="infoData.intention" disabled text-color="#ff9900"></el-rate>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <div v-if="infoData.status === 1" class="state-up-v">服务中</div>
          <div v-else-if="infoData.status === 0" class="state-down-v">未服务</div>
          <div v-else-if="infoData.status === 2" class="state-red-v">已退费</div>
        </el-descriptions-item>
        <el-descriptions-item label="归属人">
          <span>{{ infoData.attributor_name }}</span>
          <el-button v-if="role === 1" size="mini" type="primary" @click="modification">修改</el-button>
        </el-descriptions-item>
      </el-descriptions>
      <div class="consult-information">
        <div class="table">
          <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="咨询信息" name="customerConsult">
              <Consult :infoData="infoData" :load="activeName === 'customerConsult'"></Consult>
            </el-tab-pane>
            <el-tab-pane label="跟进信息" name="rollowRecord">
              <Follow :infoData="infoData" :load="activeName === 'rollowRecord'"></Follow>
            </el-tab-pane>
          </el-tabs>

        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" append-to-body title="绑定" width="30%">
      <div style="padding: 10px">
        <el-form ref="form" :model="form" label-width="100px" size="mini">
          <el-form-item label="用户ID: " label-position="left">
            <div>
              <div class="flex">
                <el-select slot="prepend" v-model="searchType" placeholder="选择类型" size="mini" @change="changeSearch">
                  <el-option :value="1" label="微信昵称"></el-option>
                  <el-option :value="2" label="UID"></el-option>
                </el-select>
                <el-select v-model="studentName" :loading="loading" :remote-method="searchStudent" clearable filterable
                  placeholder="请输入关键词" remote reserve-keyword size="mini" @change="selectUser" @clear="clearUser">
                  <el-option v-for="item in users" :key="item.id" :label="item.name" :value="item.id"
                    style="height: 40px; line-height: 40px">
                    <div style="
                                            display: flex;
                                            justify-content: start;
                                            align-items: center;
                                            height: 40px;
                                            line-height: 40px;
                                          ">
                      <span style="margin-right: 10px"> {{ item.id }} </span>
                      <el-avatar :src="item.avatar_url" size="small"></el-avatar>
                      <span style="margin-left: 10px">{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </div>
              <div v-if="notData" class="margin-t-sm">暂无数据</div>
              <div v-if="JSON.stringify(selectCurrentUser) !== '{}'" style="
                                      margin-top: 12px;
                                      display: flex;
                                      align-items: center;
                                      padding: 10px;
                                      border-radius: 4px;
                                      margin: 4px;
                                    ">
                <el-avatar :src="selectCurrentUser.avatar_url" size="small"></el-avatar>
                <span style="margin-left: 4px">{{
                  selectCurrentUser.name
                }}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button :loading="loading" size="small" type="primary" @click="onSubmit">
              保存
            </el-button>
            <el-button size="small" style="margin-left: 10px" @click="dialogVisible = false">
              取消
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="modificationDialogVisible" append-to-body title="修改归属人" width="30%">
      <div style="padding: 10px">
        <el-form ref="form" v-loading="formLoading" :model="belonger" label-width="120px" size="mini">
          <el-form-item class="input" label="归属人：" label-position="left">
            <el-select v-model="belonger.attributor_id" placeholder="请选择归属人" style="width: 200px">
              <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button :loading="loading" size="small" type="primary" @click="modificationOnSubmit">保存</el-button>
            <el-button size="small" style="margin-left: 10px" @click="modificationDialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>









  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { config } from "@/js/config";
import Consult from "./consult/list.vue"
import Follow from "./follow/list.vue"
export default {
  components: {
    Consult,
    Follow
  },
  computed: {
    ...mapState({
      role: (state) => state.user.info.role,
      userID: (state) => state.user.info.id,
    }),
  },
  data() {
    return {
      active: 0,
      searchData: {},
      studentName: "",
      list: [],
      listLoading: false,
      page: 1,
      users: [],
      pageSize: 5,
      total: 0,
      infoData: {},
      infoLoading: false,
      tableLoading: false,
      path: config.imageUrl,
      tableList: [],
      activeName: "customerConsult",
      tablePage: 1,
      tablePageSize: 10,
      tableTotal: 0,
      dialogVisible: false,
      form: {},
      searchType: 1,
      selectCurrentUser: {},
      notData: false,
      loading: false,
      modificationDialogVisible: false,
      options: [],
      belonger: {},
      formLoading: false,
      unclaimed:0,
      claimed:0,


    };
  },
  methods: {
    ...mapActions("customerCard", [
      "listCustomer",
      "detailCustomer",
      "listCustomerConsult",
      "delCustomerConsult",
      "listCustomerRefund",
      "bindUser",
      "unbindUser",
      "delCustomerRefund",
      "listCustomerFollowRecord",
      "delCustomerFollowRecord",
      "listCustomerSale",
      "changeAttributor",
      "gainCustomer",
    ]),
    ...mapActions("user", ["getUserList"]),
    ...mapActions("ruleDistribution", ["selectCounselor"]),



    editInfo() {
      this.$router.push('/customerCard/edit/' + this.infoData.id)
      localStorage.setItem("customerCardPage", this.page);
      localStorage.setItem("customerCardUserInfoId", this.infoData.id);
    },
    async gain() {
      const { res_info } = await this.gainCustomer();
      if (res_info !== "ok") return;
      this.$message.success("获取成功");
      await this.getList();
    },
    async modification() {
      this.modificationDialogVisible = true;
      this.formLoading = true;
      const { data } = await this.selectCounselor();
      this.options = data;
      this.formLoading = false;
    },
    async modificationOnSubmit() {
      if (!this.belonger.attributor_id)
        return this.$message.error("请选择归属人");
      let form = {
        customers_id: this.infoData.id,
        attributor_id: this.belonger.attributor_id,
      };
      const { res_info } = await this.changeAttributor(form);
      this.modificationDialogVisible = false;
      if (res_info !== "ok") return;
      this.$message.success("修改成功");
      await this.getUserInfo(this.infoData);
    },
    
    async unfasten() {
      const { res_info } = await this.unbindUser({ id: this.infoData.id });
      if (res_info !== "ok") return;
      this.$message.success("解绑成功");
      // this.getUserInfo(this.infoData)
      await this.getList();
    },
    async onSubmit() {
      this.loading = true;
      let form = {
        user_id: this.form.userId,
        id: this.infoData.id,
      };
      const { res_info } = await this.bindUser(form);
      this.dialogVisible = false;
      if (res_info !== "ok") return;
      this.$message.success("绑定成功");
      // this.getUserInfo(this.infoData)
      await this.getList();
      this.loading = false;
    },
    changeSearch() { },
    async refundRecordDeleted(row) {
      const { res_info } = await this.delCustomerRefund({ id: row.id });
      if (res_info !== "ok") return;
      await this.getconsultInformation(this.infoData.id);
      this.$message.success("删除成功");
    },
    selectUser(id) {
      this.users.forEach((item) => {
        if (id === item.id) {
          this.selectCurrentUser.name = item.name;
          this.selectCurrentUser.id = item.id;
          this.selectCurrentUser.avatar_url = item.avatar_url;
          this.form.userId = item.id;
        }
      });
      this.form.user_id = id;
    },
    clearUser() { },
    searchStudent(v) {
      let searchData = {};
      if (this.searchType === 1) {
        searchData.name = v;
      } else {
        searchData.uid = v;
      }

      this.notData = false;
      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.users = res.data.list;
      });
    },
    binding() {
      this.dialogVisible = true;
    },
    setTablePageSize(value) {
      this.tablePageSize = value;
      if (!this.infoData.id) return
      switch (this.activeName) {
        case "customerConsult":
          this.getconsultInformation(this.infoData.id);
          return;
        case "refundRecord":
          this.getRefundRecord(this.infoData.id);
          return;
        case "rollowRecord":
          this.getRollowRecord(this.infoData.id);
          return;
        case "customerSale":
          this.getCustomerSale(this.infoData.id);
          return;
      }
    },
    async getCustomerSale(customers_id) {
      this.tableLoading = true;
      let form = {
        customers_id,
        page: this.tablePage,
        pageSize: this.tablePageSize,
      };
      const { data } = await this.listCustomerSale(form);

      this.tableList = data.list.map((v) => {
        v.pic = v.pic.map((c) => this.path + c);
        return v;
      });
      this.tableTotal = data.total;
      this.tableLoading = false;
    },
    async getRollowRecord(customers_id) {
      this.tableLoading = true;
      let form = {
        customers_id,
        page: this.tablePage,
        pageSize: this.tablePageSize,
      };
      const { data } = await this.listCustomerFollowRecord(form);
      this.tableList = data.list.map((v) => {
        v.pics = v.pics.map((c) => this.path + c);
        return v;
      });
      this.tableTotal = data.total;
      this.tableLoading = false;
    },
    setTablePage(value) {
      this.tablePage = value;
      if (!this.infoData.id) return
      switch (this.activeName) {
        case "customerConsult":
          this.getconsultInformation(this.infoData.id);
          return;
        case "refundRecord":
          this.getRefundRecord(this.infoData.id);
          return;
        case "rollowRecord":
          this.getRollowRecord(this.infoData.id);
          return;
        case "customerSale":
          this.getCustomerSale(this.infoData.id);
          return;
      }
    },
    handleClick(tab, event) {
      localStorage.setItem("customerCard", this.activeName);

    },
    getType(type) {
      // 咨询类型：1.书本，2.课程，3.资料，4.其他
      switch (type) {
        case 1:
          return "书本";
        case 2:
          return "课程";
        case 3:
          return "资料";
        case 4:
          return "其他";
      }
    },
    source(type) {
      switch (type) {
        // 来源 1.小红书，2.抖音，3.头条，4.百度，5.微博，6.其他
        case 1:
          return "小红书";
        case 2:
          return "抖音";
        case 3:
          return "头条";
        case 4:
          return "百度";
        case 5:
          return "微博";
        case 6:
          return "其他";
        case 7:
          return "微信公众号";
      }
    },
    payType(type) {
      // 收款方式 1 微信 2 微信对公 3 支付宝 4 短书
      switch (type) {
        case 1:
          return "微信";
        case 2:
          return "微信对公";
        case 3:
          return "支付宝";
        case 4:
          return "短书";
      }
    },
    search() {
      this.size = 1;
      this.getList();
    },
    async getUserInfo(row) {
      this.active = row.id;
      this.infoLoading = true;
      const { data } = await this.detailCustomer({ id: row.id });
      this.infoData = data;
      await this.handleClick();
      this.infoLoading = false;
    },

    async getRefundRecord(customers_id) {
      this.tableLoading = true;
      let form = {
        customers_id,
        page: this.tablePage,
        pageSize: this.tablePageSize,
      };
      const { data } = await this.listCustomerRefund(form);

      this.tableList = data.list.map((v) => {
        v.pics = v.pics.map((c) => this.path + c);
        return v;
      });
      this.tableTotal = data.total;
      this.tableLoading = false;
    },
    async getconsultInformation(customers_id) {
      this.tableLoading = true;
      let form = {
        customers_id,
        page: this.tablePage,
        pageSize: this.tablePageSize,
      };
      const { data } = await this.listCustomerConsult(form);

      this.tableList = data.list.map((v) => {
        v.pics = v.pics.map((c) => this.path + c);
        return v;
      });
      this.tableTotal = data.total;
      this.tableLoading = false;
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.listLoading = true;
      let page = 1;
      if (localStorage.getItem("customerCardPage")) {
        page = parseInt(localStorage.getItem("customerCardPage"));
        localStorage.removeItem("customerCardPage");
      } else {
        page = this.page;
      }
      let form = {
        ...this.searchData,
        page,
        pageSize: this.pageSize,
      };
      const { data } = await this.listCustomer(form);
      this.list = data.list;
      this.unclaimed = data.unclaimed;
      this.claimed = data.claimed;
      this.total = data.total;
      this.page = page;
      if (data.total !== 0) {
        let obj = {};
        if (localStorage.getItem("customerCardUserInfoId")) {
          obj.id = parseInt(localStorage.getItem("customerCardUserInfoId"));
          localStorage.removeItem("customerCardUserInfoId");
        } else {
          obj = data.list[0];
        }
        await this.getUserInfo(obj);
      }

      this.listLoading = false;
    },
  },
  mounted() {
    this.activeName = localStorage.getItem("customerCard");
    this.getList();
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  background: #f4f5f9;
  display: flex;
}

.list {
  background: #fff;
  width: 320px;
  height: 100%;
  overflow: auto;
  margin-right: 10px;
  padding: 10px 10px 40px 10px;
  position: relative;
}

.info-information {
  width: calc(100% - 320px);
  height: 100%;
  overflow: auto;
  background: #fff;
  padding: 20px;
}

.search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.search-select {
  width: 30%;
  margin-right: 10%;
  margin-bottom: 10px;
}

.search-input {
  width: 60%;
  margin-bottom: 10px;
}

.list-item {
  margin: auto;
  padding: 15px 10px;
  border-radius: 8px;
  background: #fcfcfc;
  margin-bottom: 10px;
  cursor: pointer;
}
.active{
  background-color: #aad4ff;
}
.block {
  width: 100%;
  left: 0;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
}

.item-info {
  display: flex;
  align-items: center;
}

.image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  background-color: #eee;
}

.id_name {
  line-height: 30px;
}

.top-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  margin-right: 20px;
}

.bottom-box {
  margin-top: 20px;
}

.state-red {
  color: red !important;
}


.binding {
  margin: 0 60px;
}

.user-info {
  padding: 10px;
  display: flex;
  align-items: center;
}

.info-top-box {
  display: flex;
  justify-content: space-between;
}

.material {
  padding: 10px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  font-size: 14px;
}

.consult-information {
  margin-top: 10px;
}

.consult-information-title {
  margin: 30px 0;
}

.consult-information-add {
  width: 100%;
  text-align: right;
  /* margin-bottom: 10px; */
}

.consult-information-title span {
  padding: 13px 30px;
  border-radius: 10px 10px 0px 0px;
  background: #e0e0e0;
}
  .get-info{
    padding: 10px;
    text-align: center;
    width: 150px;
    border-radius: 8px;
  }
</style>
